/* @refresh reload */
import { render } from 'solid-js/web';
import { Router } from "@solidjs/router";
import "virtual:uno.css"
import { MetaProvider } from '@solidjs/meta';
import App from './App';
import './style.css';

const root = document.getElementById('root');

if (import.meta.env.DEV && !(root instanceof HTMLElement)) {
  throw new Error(
    'Root element not found. Did you forget to add it to your index.html? Or maybe the id attribute got misspelled?',
  );
}

render(() => <><Router><MetaProvider><App /></MetaProvider></Router></>, root!);
